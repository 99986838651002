.service{
    .part1{
        height: 510px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .part1_title{
            font-size: 28px;
            font-family: Source Han Sans CN;
            font-weight: 500;
            color: #171C46;
            margin-bottom: 16px;
        }
        .part1_text{
            font-size: 14px;
            font-family: Source Han Sans CN;
            font-weight: 500;
            color: #171C46;
            margin-bottom: 48px;
        }
        .part1_flex{
            width: 1200px;
            display: flex;
            justify-content: space-between;
            .flex_item{
                width: 350px;
                height: 157px;
                background: #FFFFFF;
                box-shadow: 0px 15px 20px 1px rgba(137, 159, 220, 0.04);
                border-radius: 20px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                .flex_item_left{
                    padding-left: 36px;
                    .flex_item_left_text1{
                        width: 89px;
                        height: 4px;
                        position: relative;
                        margin-bottom: 20px;
                        &::before{
                            content: attr(text);
                            position: absolute;
                            bottom: 0px;
                            left: 0;
                            right: 0;
                            font-size: 18px;
                            font-family: Source Han Sans CN;
                            font-weight: 500;
                            color: #222222;
                            white-space: nowrap;
                        }
                    }
                    .flex_item_left_text2{
                        font-size: 14px;
                        font-family: Source Han Sans CN;
                        font-weight: 400;
                        color: #5D6372;
                        line-height: 24px;
                    }
                }
                img{
                    width: 98px;
                    height: 98px;
                    align-self: flex-end;
                }
                &:hover{
                    box-shadow: 0px 15px 20px 1px rgba(137, 159, 220, 0.2);
                    animation-play-state: paused;
                }
            }
            .a1{
                animation: move 12s linear infinite;
            }
            .a2{
                animation: move 12s linear -4s infinite;
            }
            .a3{
                animation: move 12s linear -8s infinite;
            }
            @keyframes move {
                0%{
                    transform: translateY(0);
                }
                25%{
                    transform: translateY(-20px);
                }
                75%{
                    transform: translateY(20px);
                }
                100%{
                    transform: translateY(0);
                }
            }
        }
    }
    .part2{
        height: 640px;
        .part2_flex{
            height: 640px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 1200px;
            margin: 0 auto;
            img{
                height: auto;
            }
            .part2_flex_title{
                font-size: 24px;
                font-family: Source Han Sans CN;
                font-weight: 500;
                color: #222222;
                line-height: 36px;
                margin-bottom: 30px;
            }
            .part2_flex_text{
                width: 450px;
                font-size: 16px;
                font-family: Source Han Sans CN;
                font-weight: 400;
                color: #5D6372;
                padding-left: 14px;
                position: relative;
                line-height: 30px;
                margin-bottom: 30px;
                &::before{
                    content: '';
                    position: absolute;
                    left: 0px;
                    top: 10px;
                    width: 8px;
                    height: 8px;
                    background: #0A54EF;
                    border-radius: 50%;
                }
            }
            .part2_flex_more{
                width: 120px;
                height: 40px;
                border: 1px solid #0A54EF;
                color: #0A54EF;
                opacity: 0.6;
                font-size: 14px;
                font-family: Source Han Sans CN;
                font-weight: 400;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
                >div{
                    width: 16px;
                    height: 16px;
                    margin-left: 4px;
                    background: url('https://boweisou.oss-cn-shenzhen.aliyuncs.com/website/news/i_arrow_2.png') no-repeat;
                    background-position: center;

                }
                &:hover{
                    background-color: #0A54EF;
                    color: #ffffff;
                    >div{
                        background-image: url('https://boweisou.oss-cn-shenzhen.aliyuncs.com/website/index/jiantou.png');
                    }
                }
            }
        }

    }
}
