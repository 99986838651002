.funs-list {
  padding: 90px 0 70px 0;
  background: #f8fbfe;
  .title {
    font-size: 28px;
    font-weight: 600;
    text-align: center;
    color: #171c46;
    margin-bottom: 60px;
  }
  .wrap-center .item {
    height: 360px;
    ul {
      display: flex;
      flex-wrap: wrap;
      li {
        width: 186px;
        height: 130px;
        background: #ffffff;
        margin-right: 15px;
        margin-bottom: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        &:hover{
          box-shadow: 0px 3px 20px 1px rgba(195, 204, 223, 0.34);
        }
        span {
          margin-top: 20px;
          color: #0c0e22;
        }
        img{
          transition: all 0.3s;
          &:hover{
            transform: scale(1.2);
          }
        }
        &:nth-child(6),
        &:nth-child(12) {
          margin-right: 0;
        }
      }
    }
  }
  .ant-carousel .slick-dots li button {
    background-color: #dcdee0;
    opacity: 0.6;
  }
  .ant-carousel .slick-dots li.slick-active button {
    background: #0a54ef;
  }
}
.case-list {
  padding: 90px 0 80px 0;
  .title {
    font-size: 28px;
    font-weight: 600;
    text-align: center;
    color: #171c46;
    margin-bottom: 70px;
  }
  ul {
    display: flex;
    flex-wrap: wrap;
    li {
      width: 284px;
      height: 320px;
      background-color: #fff;
      border: 1px solid #e4e9ee;
      position: relative;
      margin-right: 20px;
      margin-bottom: 22px;
      &:nth-child(4),
      &:nth-child(8) {
        margin-right: 0;
      }
      .info {
        .img {
          width: 100%;
          height: 160px;
        }
        .logo {
          position: relative;
          top: -36px;
          left: 24px;
          border-radius: 50%;
          background-color: #fff;
          width: 72px;
          height: 72px;
          box-shadow: 0px 2px 8px 0px rgba(10, 84, 239, 0.1);
          img {
            border-radius: 50%;
            width: 100%;
            height: 100%;
          }
        }
        .content {
          margin-top: -15px;
          padding: 0 22px;
          p:nth-child(1) {
            color: #0c0e22;
            font-weight: 600;
            font-size: 16px;
            margin-bottom: 10px;
          }
          p:nth-child(2) {
            color: #939399;
            line-height: 24px;
          }
        }
      }
      .code {
        transition: all 0.3s linear;
        transform: scale(0);
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #fff;
        z-index: 10;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .img {
          width: 120px;
          height: 120px;
          border: 1px solid #e4e9ee;
          border-radius: 50%;
          img {
            width: 100%;
            height: 100%;
            border-radius: 50%;
          }
        }
        .name {
          font-size: 16px;
          font-weight: 600;
          margin-top: 30px;
          color: #0c0e22;
        }
      }
    }
  }
}
.component {
  height: 640px;
  .content {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .title {
      color: #fff;
      font-size: 28px;
      font-weight: 600;
      margin-bottom: 35px;
    }
    .introduction {
      color: #fefeff;
      width: 560px;
      line-height: 28px;
      opacity: 0.6;
      margin-bottom: 90px;
      text-align: center;
    }
    ul {
      display: flex;
      width: 100%;
      justify-content: space-between;
      margin-bottom: 110px;
      li {
        display: flex;
        flex-direction: column;
        align-items: center;
        span {
          color: #fff;
          font-size: 18px;
          margin-top: 15px;
        }
      }
    }
  }
}
.platform-drainage {
  height: 1000px;
  .content {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .title {
      color: #171c46;
      font-size: 28px;
      font-weight: 600;
      margin-bottom: 35px;
    }
    .introduction {
      color: #171c46;
      margin-bottom: 100px;
    }
    .platform {
      display: flex;
      align-items: center;
      .left {
        .platform-item {
          width: 300px;
          height: 160px;
          background: #ffffff;
          box-shadow: 0px 4px 18px 2px rgba(10, 84, 239, 0.1);
          margin-bottom: 55px;
          padding: 24px 24px 0 24px;
          cursor: pointer;
          .logo {
            display: flex;
            justify-content: space-between;
            div {
              width: 64px;
              height: 28px;
              border: 1px solid #ffffff;
              opacity: 0.5;
              border-radius: 14px;
              font-size: 12px;
              display: flex;
              align-items: center;
              justify-content: center;
              color: #fff;
            }
          }
          .name {
            font-size: 16px;
            font-weight: bold;
            color: #171c46;
            margin: 24px 0 10px 0;
          }
          p {
            color: #5d6372;
          }
          &:last-child {
            margin-bottom: 0;
            margin-left: 94px;
          }
          &:first-child {
            margin-left: 94px;
          }
        }
      }
      .center {
        margin: 0 56px;
        .platform-item {
          width: 300px;
          height: 160px;
          background: #ffffff;
          box-shadow: 0px 4px 18px 2px rgba(10, 84, 239, 0.1);
          margin-bottom: 55px;
          padding: 24px 24px 0 24px;
          cursor: pointer;
          .logo {
            display: flex;
            justify-content: space-between;
            div {
              width: 64px;
              height: 28px;
              border: 1px solid #ffffff;
              opacity: 0.5;
              border-radius: 14px;
              font-size: 12px;
              display: flex;
              align-items: center;
              justify-content: center;
              color: #fff;
            }
          }
          .name {
            font-size: 16px;
            font-weight: bold;
            color: #171c46;
            margin: 24px 0 10px 0;
          }
          p {
            color: #5d6372;
          }
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
      .right {
        .platform-item {
          width: 300px;
          height: 160px;
          background: #ffffff;
          box-shadow: 0px 4px 18px 2px rgba(10, 84, 239, 0.1);
          margin-bottom: 55px;
          padding: 24px 24px 0 24px;
          cursor: pointer;
          .logo {
            display: flex;
            justify-content: space-between;
            div {
              width: 64px;
              height: 28px;
              border: 1px solid #ffffff;
              opacity: 0.5;
              border-radius: 14px;
              font-size: 12px;
              display: flex;
              align-items: center;
              justify-content: center;
              color: #fff;
            }
          }
          .name {
            font-size: 16px;
            font-weight: bold;
            color: #171c46;
            margin: 24px 0 10px 0;
          }
          p {
            color: #5d6372;
          }
          &:last-child {
            margin-bottom: 0;
          }
          &:nth-child(2) {
            margin-left: 94px;
          }
        }
      }
      .platform-active {
        background: linear-gradient(180deg, #0a54ef, #4883ff) !important;
        .name {
          color: #fff !important;
        }
        p {
          color: #fff !important;
        }
      }
    }
  }
}
.illustration {
  .func-introduction {
    &:nth-child(1),
    &:nth-child(3) {
      background: #f8fbfe;
    }
    .content {
      display: flex;
      align-items: center;
      .info {
        .name {
          color: #171c46;
          font-weight: 600;
          font-size: 28px;
          margin-bottom: 35px;
        }
        p {
          color: #171c46;
          line-height: 28px;
          margin-bottom: 90px;
        }
      }
    }
  }
}
