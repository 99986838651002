.big-data {
    height: 340px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .title {
      font-size: 28px;
      font-weight: 600;
      color: #171c46;
      margin-bottom: 80px;
    }
    ul {
      display: flex;
      li {
        flex: 1;
        color: #171c46;
        display: flex;
        align-items: center;
        flex-direction: column;
        .num {
          display: flex;
          align-items: center;
          font-weight: 600;
          font-size: 34px;
          margin-bottom: 20px;
          img {
            margin-right: 13px;
          }
        }
        .name {
          font-size: 16px;
          opacity: 0.6;
        }
      }
    }
  }