.right-fixed {
  position: fixed;
  z-index: 100;
  top: 50%;
  right: 15px;
  transform: translateY(-50%);
  width: 70px;
  .title {
    height: 180px;
    color: #fff;
    font-size: 24px;
    font-weight: 600;
    padding: 20px 23px;
    line-height: 35px;
  }
  .ant-back-top {
    position: static;
  }
  .connect,
  .wx,
  .thepublic,
  .top {
    width: 70px;
    height: 80px;
    background-color: #fff;
    box-shadow: 0px 0px 40px 0px rgba(205, 213, 221, 0.69);
    color: #171c46;
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    position: relative;
    .code {
      width: 160px;
      height: 180px;
      background: #ffffff;
      box-shadow: 0px 0px 40px 0px rgba(205, 213, 221, 0.69);
      position: absolute;
      left: -170px;
      top: -10px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      font-size: #171c46;
      img {
        margin-bottom: 10px;
        width: 120px;
        height: 120px;
      }
    }
    .lianxi {
      width: 280px;
      height: 80px;
      background: #ffffff;
      box-shadow: 0px 0px 40px 0px rgba(205, 213, 221, 0.69);
      position: absolute;
      left: -290px;
      top: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      p:nth-child(1) {
        color: #171c46;
        font-size: 13px;
        margin-bottom:10px;
      }
      p:nth-child(2) {
        color: #0e57ef;
        font-size: 28px;
        font-weight: bold;
      }
    }
    img {
      margin-bottom: 10px;
    }
  }
}
