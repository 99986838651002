.details{
    background-color: #F5F6F7 ;
    overflow: hidden;
    .d_main{
        width: 1200px;
        margin: 0 auto 80px;
        .d_main_header{
            height: 54px;
            display: flex;
            align-items: center;
            font-size: 14px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #0C0E22;
            img{
                width: 14px;
                height: 14px;
                margin:0 4px;
            }
        }
        .d_box{
            display: flex;
            justify-content: space-between;
            article{
                width: 820px;
                background: #FFFFFF;
                padding: 40px 40px 0;
                .d_box_header{
                    font-size: 24px;
                    font-family: Source Han Sans CN;
                    font-weight: bold;
                    color: #171C46;
                    text-align: center;
                }
                .d_desc{
                    display: flex;
                    justify-content: center;
                    margin-top: 23px;
                    font-size: 14px;
                    font-family: Source Han Sans CN;
                    font-weight: 400;
                    color: #999999;
                    margin-bottom: 16px;
                }
                .d_article{
                    padding: 22px 0 46px;
                    border-bottom: 1px solid #F0F0F0;
                    position: relative;
                    font-size: 18px;
                    font-family: Source Han Sans CN;
                    font-weight: 400;
                    color: #5C5F66;
                    line-height: 32px;
                    &::before{
                        content: '';
                        position: absolute;
                        left: -24px;
                        right: -24px;
                        height: 1px;
                        top: 0px;
                        background-color: #F0F0F0;
                    }
                    b,strong{
                        font-weight: bolder;
                    }
                    img{
                        max-width: 100%;
                        height: auto;
                        margin: 18px auto;
                    }
                    p{
                        padding:18px 0;
                    }
                }
                .d_footer{
                    height: 84px;
                    display: flex;
                    align-items: center;
                    .d_footer_text{
                        font-size: 14px;
                        font-family: Source Han Sans CN;
                        font-weight: 400;
                        color: #0C0E22;
                    }
                    img{
                        width: 36px;
                        height: 36px;
                        margin-left: 8px;
                        cursor: pointer;
                    }
                }
            }
            .d_card{
                width: 360px;
                height: 160px;
                background: #FFFFFF;
                margin-bottom: 20px;
                &:hover{
                    box-shadow: 1px 2px 4px 2px rgba(0, 0, 0, 0.1);
                }
            }
            .d_card1{
                padding: 10px;
                display: flex;
                align-items: center;
                border: 1px solid #E6E6E6;
                img{
                    width: 140px;
                    height: 140px;
                    margin-right: 15px;
                }
                .d_card1_text1{
                    font-size: 16px;
                    font-family: Source Han Sans CN;
                    font-weight: 500;
                    color: #0C0E22;
                    margin-bottom: 10px;
                }
                .d_card1_text2{
                    font-size: 16px;
                    font-family: Source Han Sans CN;
                    font-weight: 500;
                    color: #0C0E22;
                    margin-bottom: 16px;
                }
                .d_card1_text3{
                    font-size: 12px;
                    font-family: Source Han Sans CN;
                    font-weight: 400;
                    color: #999999;
                }
            }
            .d_card2{
                display: flex;
                padding: 24px 27px;
                border: 1px solid #E6E6E6;
                img{
                    width: 30px;
                    height: 30px;
                    margin-right: 23px;
                }
                .d_card2_text1{
                    font-size: 18px;
                    font-family: Source Han Sans CN;
                    font-weight: bold;
                    color: #0C0E22;
                    margin-bottom: 16px;
                }
                .d_card2_text2{
                    font-size: 14px;
                    font-family: Source Han Sans CN;
                    font-weight: 400;
                    color: #666666;
                    margin-bottom: 12px;
                }
                .d_card2_text3{
                    font-size: 14px;
                    font-family: Source Han Sans CN;
                    font-weight: 400;
                    color: #666666;
                    line-height: 24px;
                }
            }
        }
    }

}
