.agriculture_list {
  .agriculture_info {
    height: 500px;
    display: flex;
    align-items: center;
    .content {
      display: flex;
      justify-content: space-between;
      .img{
        width: 460px;
        height: 350px;
        overflow: hidden;
        img{
          width: 100%;
          height: 100%;
          transition: all 0.3s;
          &:hover{
            transform:scale(1.2);
          }
        }
      }
      .info {
        position: relative;
        .englishName {
          padding: 5px 17px;
          color: #0a54ef;
          border: 1px solid #0a54ef;
          display: inline-block;
          font-weight: 600;
          margin-bottom: 25px;
        }
        .name {
          font-size: 30px;
          font-weight: 600;
          color: #000;
          margin-bottom: 25px;
        }
        .introduction {
          font-size: 13px;
          color: #666;
          margin-bottom: 30px;
        }
        .more-info {
          font-size: 13px;
          div {
            color: #666;
            margin-bottom: 20px;
            span {
              color: #0a54ef;
            }
          }
        }
        .tryout {
          position: absolute;
          left: 0;
          bottom: 0;
        }
      }
    }
  }
}
