.help-con {
    display: flex;
    margin: 30px auto;
    .help-type {
        width: 170px;
        .collapse {
            overflow: hidden;
            padding: 20px 0;
            font-size: 16px;
            border-bottom: 1px solid #eaeaea;
            .collapse-item {
                display: flex;
                justify-content: space-between;
                cursor: pointer;
                &:hover {
                    color: #0a54ef;
                }
                i {
                    transition: all 0.3s linear;
                }
            }
            .collapse-item-level2 {
                font-size: 14px;
                margin: 20px 0 0;
                cursor: pointer;
            }
        }
    }
    .help-list {
        flex: 1;
        margin-left: 30px;
        display: flex;
        flex-direction: column;
        border: 1px solid #eaeaea;
        .help-box {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            flex: 1;
        }
        .help-type-name {
            padding-left: 20px;
            line-height: 50px;
            border-bottom: 1px solid #eaeaea;
            font-size: 15px;
            font-weight: 600;
        }
        .help-title {
            padding-left: 20px;
            .help-title-item {
                height: 50px;
                display: flex;
                align-items: center;
                position: relative;
                &:hover {
                    color: #0a54ef;
                }
                span {
                    padding-left: 15px;
                    cursor: pointer;
                    &:before {
                        position: absolute;
                        content: "";
                        width: 4px;
                        height: 4px;
                        border-radius: 50%;
                        left: 0;
                        background-color: black;
                        top: 50%;
                        transform: translateY(-50%);
                    }
                }
            }
        }
        .help-pagination {
            padding-right: 10px;
            display: flex;
            justify-content: flex-end;
            background-color: #f8f9fb;
        }
    }
    .help-content{
        flex:1;
        margin-left: 30px;
        h1{
            font-size:20px;
            font-weight: bold;
            margin-bottom: 20px;
        }
    }
}
