.member{
    .part1{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 823px;
        .part1_title{
            font-size: 28px;
            font-family: Source Han Sans CN;
            font-weight: 500;
            color: #171C46;
            margin-bottom: 80px;
        }
        .part1_flex{
            display: flex;
            width: 1254px;
            flex-wrap: wrap;
            .part1_flex_item{
                width: 300px;
                height: 255px;
                background: #FFFFFF;
                border-radius: 10px;
                margin-bottom: 18px;
                text-align: center;
                padding: 0 17px;
                &:hover{
                    box-shadow: 0px 3px 20px 1px rgba(195, 204, 223, 0.34);
                    &::after,+ .hasline::after{
                        background: transparent;
                    }
                    img{
                        transform: scale(1.2);
                    }
                }
                img{
                    width: 60px;
                    height: 60px;
                    margin-top: 22px;
                    transition: 0.4s;
                }
                .part1_flex_item_title{
                    font-size: 18px;
                    font-family: Source Han Sans CN;
                    font-weight: 500;
                    color: #171C46;
                    margin-top: 24px;
                }
                .part1_flex_item_text{
                    font-size: 14px;
                    font-family: Source Han Sans CN;
                    font-weight: 500;
                    color: #5D6372;
                    line-height: 20px;
                    margin-top: 17px;
                }
            }
            .hasline{
                position: relative;
                margin-left: 18px;
                &::after{
                    position: absolute;
                    content: "";
                    left: -10px;
                    width: 2px;
                    height: 180px;
                    top: 28px;
                    background: #F1F1F5;
                }
            }
        }
    }
    .part2{
        height: 800px;
        position: relative;
        overflow: hidden;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        .part2_bj{
            position: absolute;
            top: 0;
            left: 50%;
            transform: translateX(-50%);
            width: 1920px;
            height: 800px;
            z-index: -1;
        }
        .part2_title{
            font-size: 28px;
            font-family: Source Han Sans CN;
            font-weight: 500;
            color: #FFFFFF;
            margin-bottom: 80px;
        }
        .part2_img1{
            width: 1200px;
            height: auto;
        }
    }
    .part3{
        height: 800px;
        background: #F8FBFE;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        position: relative;
        .part3_title{
            font-size: 28px;
            font-family: Source Han Sans CN;
            font-weight: 500;
            color: #171C46;
            margin-bottom: 50px;
        }
        img{
            width: 649px;
            height: auto;
        }
        .part3_item{
            position: absolute;
            width: 300px;
            height: 148px;
            background: #FFFFFF;
            box-shadow: 0px 3px 23px 1px rgba(151, 173, 196, 0.14);
            border-radius: 10px;
            padding: 24px;
            transition: 0.4s;
            &:hover{
                transform: translateY(-20px);
            }
            .part3_item_title{
                display: flex;
                align-items: center;
                margin-bottom: 14px;
                :first-child{
                    font-size: 24px;
                    font-family: DIN;
                    font-weight: bold;
                    color: #0A54EF;
                    margin-right: 8px;
                }
                :nth-child(2){
                    font-size: 18px;
                    font-family: Source Han Sans CN;
                    font-weight: 500;
                    color: #171C46;
                }
            }
            .part3_item_text{
                font-size: 14px;
                font-family: Source Han Sans CN;
                font-weight: 500;
                color: #5D6372;
                line-height: 24px;
            }
        }
        .part3_item1{
            left: calc(50% - 500px);
             top: 240px;
        }
        .part3_item2{
            left: calc(50% - 580px);
             bottom: 107px;
        }
        .part3_item3{
            left: calc(50% + 274px);
             top: 210px;
        }
        .part3_item4{
            left: calc(50% + 310px);
             bottom: 117px;
        }
    }
    .part4{
        height: 700px;
        background: #Ffffff;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        .part4_title{
            font-size: 28px;
            font-family: Source Han Sans CN;
            font-weight: 500;
            color: #171C46;
            margin-bottom: 90px;
        }
        .case{
            display: flex;
            justify-content: center;
            .case_item{
                width: 272px;
                height: 351px;
                background: #FFFFFF;
                box-shadow: 0px 3px 84px 3px rgba(151, 173, 196, 0.14);
                border-radius: 10px;
                overflow: hidden;
                 position: relative;
                 text-align: center;
                &:not(:last-child){
                    margin-right: 37px;
                }
                .case_img{
                    position: relative;
                    width: 272px;
                    height: 235px;
                    &::after{
                        content: '';
                        position: absolute;
                        top: 0;
                        right: 0;
                        bottom: 0;
                        left: 0;
                        background: #000;
                        opacity: 0.2;
                        z-index: 1;
                    }
                    >img{
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                    div{
                        position: absolute;
                        width: 73px;
                        height: 73px;
                        border-radius: 50%;
                        left: 50%;
                        bottom: 0;
                        transform: translate(-50%,50%);
                        background-color: #fff;
                        box-shadow: 0px 2px 8px 0px rgba(10, 84, 239, 0.1);
                        z-index: 2;
                        img{
                            width: 73px;
                            height: 73px;
                            border-radius: 50%;
                        }
                    }
                }
                .case_code{
                    width: 177px;
                    height: 177px;
                    position: absolute;
                    top: 68px;
                    left: 50%;
                    transform: translateX(-50%) scale(0);
                    transition: 0.4s;
                    border-radius: 50%;
                }
                &:hover{
                    .case_code{
                        transform: translateX(-50%) scale(1);
                    }
                    .case_img{
                       display: none;
                    }
                }
                .case_text{
                    font-size: 20px;
                    font-family: Source Han Sans CN;
                    font-weight: 500;
                    color: #333333;
                    text-align: center;
                    position: absolute;
                    bottom: 44px;
                    left: 0;
                    right: 0;
                }
            }
        }
    }
}
