.join{
    .part{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .part_title{
            font-size: 28px;
            font-family: Source Han Sans CN;
            font-weight: 500;
            color: #171C46;
        }
    }
    .part1{
        height: 600px;
        background: #F8FBFE;
        .part1_item{
            width: 585px;
            height: 147px;
            background: #FFFFFF;
            border: 1px solid #E3E3E3;
            padding: 38px;
            display: flex;
            margin-top: 28px;
            img{
                width: 64px;
                height: 64px;
                margin-right: 26px;
                transition: 0.4s;
            }
            .part1_right{
                flex: 1;
                .part1_title{
                    font-size: 20px;
                    font-family: Source Han Sans CN;
                    font-weight: bold;
                    color: #333333;
                    margin-bottom: 14px;
                }
                .part1_text{
                    width: 390px;
                    font-size: 14px;
                    font-family: Source Han Sans CN;
                    font-weight: 400;
                    color: #999999;
                    line-height: 22px;
                }
            }
            &:hover{
                box-shadow: 0px 3px 23px 1px rgba(151, 173, 196, 0.34);
                img{
                    transform: scale(1.2)
                }
            }
        }
    }
    .part2{
        height: 720px;
        background: #ffffff;
        .part2_item{
            width: 384px;
            height: 420px;
            background: #FFFFFF;
            border: 1px solid #EAEAEA;
            overflow: hidden;
            img{
                width: 384px;
                height: 240px;
                transition: 0.4s;
            }
            .part2_bottom{
                padding: 28px;
                .part2_title{
                    font-size: 20px;
                    font-family: Source Han Sans CN;
                    font-weight: bold;
                    color: #333333;
                    margin-bottom: 20px;
                }
                .part2_text{
                    font-size: 14px;
                    font-family: Source Han Sans CN;
                    font-weight: 400;
                    color: #999999;
                    line-height: 24px;
                }
            }
            &:hover img{
                transform: scale(1.2);
            }
        }
    }
    .part3{
        height: 580px;
        background: #F8FBFE;
        .part3_item{
            width: 182px;
            height: 78px;
            background: #FFFFFF;
            border: 1px solid #E2E2E2;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 20px;
            box-shadow: 0 0 1px 1px #E2E2E2;
            transition: 0.4s;
            img{
                max-width: 182px;
                max-height: 78px;
            }
             &:hover{
                transform: scale(1.1);
            }
        }
    }
    .part4{
        height: 534px;
        background: #ffffff;
        .part4_flex{
            width: 1200px;
            height: 200px;
            background: #FFFFFF;
            border: 1px solid #EAEAEA;
            box-shadow: 0px 8px 40px 0px rgba(205, 213, 221, 0.35);
            display: flex;
            align-items: center;
            justify-content: center;
            .part4_icon1{
                width: 50px;
                height: 50px;
                margin-right: 16px;

            }
            .part4_text{
                font-size: 16px;
                font-family: Source Han Sans CN;
                font-weight: 500;
                color: #333333;
            }
            .part4_icon2{
                width: 24px;
                height: 24px;
                margin: 0 70px;
            }
            .a1{
                animation: a1 0.6s linear infinite alternate;
            }
            .a2{
                animation: a1 0.6s linear -0.2s infinite alternate;
            }
            .a3{
                animation: a1 0.6s linear -0.4s infinite alternate;
            }
            @keyframes a1 {
                0%{
                    opacity: 0.3;
                }
                100%{
                    opacity: 1;
                }
            }
        }
    }
    .part5{
         height: 750px;
        background: #F8FBFE;
        position: relative;
        .part5_form{
            .part5_input{
                width: 600px;
                height: 64px;
                background: #FFFFFF;
                box-shadow: 0px 3px 3px 0px rgba(3, 22, 76, 0.17);
                padding:  0 24px;
                display: flex;
                align-items: center;
                margin-bottom: 26px;
                input{
                    font-size: 16px;
                    font-family: Source Han Sans CN;
                    font-weight: 500;
                    flex: 1;
                }
            }
            .part5_star{
                font-size: 16px;
                font-family: Source Han Sans CN;
                font-weight: 500;
                color: #E52020;
                margin-right: 2px;
                margin-top: 2px;
            }
            .part5_textarea{
                width: 600px;
                height: 130px;
                background: #FFFFFF;
                box-shadow: 0px 3px 3px 0px rgba(3, 22, 76, 0.17);
                margin-bottom: 43px;
                 padding: 24px;
                 display: flex;
                textarea{
                    font-size: 16px;
                    font-family: Source Han Sans CN;
                    font-weight: 500;
                    width: 100%;
                    height: 100%;
                }
            }
            .part5_submit{
                width: 200px;
                height: 50px;
                background: #0A54EF;
                border: 1px solid #1F78F2;
                box-shadow: 0px 3px 3px 0px rgba(3, 22, 76, 0.17);
                display: flex;
                align-items: center;
                justify-content: center;
                margin: 0 auto;
                cursor: pointer;
                div{
                    font-size: 16px;
                    font-family: Source Han Sans CN;
                    font-weight: 500;
                    color: #FFFEFE;
                    margin-right: 10px;
                }
                img{
                    width: 13px;
                    height: 9px;
                }
            }
        }
    }
    .part_flex{
        width: 1200px;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }
    .message{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        width: 348px;
        height: 197px;
        background: rgba(0,0,0,0.7);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .message_text1{
            font-size: 16px;
            font-family: Source Han Sans CN;
            font-weight: 500;
            color: #FFFFFF;
        }
        .iknow{
            width: 156px;
            height: 44px;
            border: 1px solid #FFFFFF;
            margin-top: 45px;
            line-height: 44px;
            text-align: center;
            font-size: 16px;
            font-family: Source Han Sans CN;
            font-weight: 500;
            color: #FFFFFF;
            cursor: pointer;
        }
    }
}
