.header {
  height: 60px;
  background: #fff;
  position: fixed;
  z-index: 100;
  left: 0;
  top: 0;
  width: 100%;
  margin-bottom:60px;
  box-shadow: 0px 2px 8px 0px rgba(10, 84, 239, 0.1);
  .wrap-center {
    display: flex;
    align-items: center;
    height: 100%;
    justify-content: space-between;
    .menu {
      display: flex;
      align-items: center;
      height: 100%;
      img {
        width: 112px;
        height: 23px;
        margin-right: 47px;
      }
      nav {
        height: 100%;
        display: flex;
      }
      nav >ul {
        display: flex;
        height: 100%;
        align-items: center;
        >li {
          margin-right: 45px;
          color: #0c0e22;
          height: 100%;
          display: flex;
          align-items: center;
          position: relative;
          cursor: pointer;
          i{
            color: #0c0e22;
            font-weight: 400;
            transition: 0.2s linear;
            margin-left:3px;
          }
          ul{
            width: 100%;
            left:50%;
            transition: 0.2s linear;
            transform: translateX(-50%);
            position: absolute;
            top:60px;
            width: 112px;
            // height: 140px;
            background-color: #fff;
            text-align: center;
            overflow: hidden;
            box-shadow: 0px 2px 2px 0px rgba(205, 213, 221, 0.35);
            li{
              font-size:12px;
              line-height: 33px;
              color: #0c0e22;
              &:first-child{
                  margin-top: 10px;
              }
              &:last-child{
                  margin-bottom: 10px;
              }
            }
          }
        }
        .menu-active:after {
          position: absolute;
          bottom: 0;
          left: 50%;
          content: "";
          height: 2px;
          width: 100%;
          padding: 0 5px;
          box-sizing: content-box;
          transform: translateX(-50%);
          background: #0a54ef;
        }
      }
    }
    .login {
      display: flex;
      align-items: center;
      span{
        cursor: pointer;
      }
      span:nth-child(1) {
        color: #0c0e22;
      }
      span:nth-child(2) {
        width: 1px;
        height: 13px;
        background: #e5e5e5;
        margin: 0 10px;
      }
      span:nth-child(3) {
        color: #0a54ef;
      }
    }
  }
}
