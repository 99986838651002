.apply{
    width: 100%;
    height: 206px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    img{
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        height: 206px;
        width: 100%;
        z-index: -1;
    }
    .apply_text1{
        font-size: 28px;
        font-family: Source Han Sans CN;
        font-weight: 500;
        color: #FFFFFF;
        margin-bottom: 28px;
    }
    .apply_text2{
        width: 120px;
        height: 40px;
        background: #FFFFFF;
        border-radius: 2px;
        text-align: center;
        line-height: 40px;
        font-size: 16px;
        font-family: Source Han Sans CN;
        font-weight: 500;
        color: #0A54EF;
        cursor: pointer;
    }
}
