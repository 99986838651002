.travel-list {
  width: 1080px;
  margin: 0 auto;
  margin-top: 88px;
  margin-bottom: 60px;
  .item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    .content {
      width: 522px;
      .name {
        font-weight: 500;
        color: #171c46;
        position: relative;
        padding-left: 23px;
        font-size: 26px;
        margin-bottom: 50px;
        &:after {
          content: "";
          position: absolute;
          left: 0;
          top: 50%;
          transform: translateY(-50%);
          width: 10px;
          height: 30px;
          background: #0a54ef;
        }
        span {
          position: absolute;
          left: 0;
          top: -35px;
          color: #5d6372;
          font-weight: bold;
          font-size: 40px;
          opacity: 0.09;
        }
      }
      .introduction {
        font-size: 17px;
        color: #5d6372;
        line-height: 32px;
        margin-bottom: 50px;
      }
      .tryout {
        color: #fff;
        width: 120px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #0a54ef;
        img {
          margin-left: 7px;
        }
      }
    }
  }
}
