.search {
    height: 320px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    h1 {
        color: #2a2b38;
        font-size: 40px;
        font-weight: 600;
    }
    .search-input {
        width: 587px;
        height: 44px;
        background-color: #fff;
        margin-top:40px;
        display: flex;
        align-items: center;
        input{
            flex:1;
            height: 100%;
            padding-left:20px;
        }
        button {
            width: 73px;
            height: 42px;
            background: #0a54ef;
            border-radius: 4px;
            border:0;
            cursor: pointer;
            img{
                width: 20px;
                height: 20px;
            }
        }
    }
}
