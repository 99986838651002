.ant-carousel .slick-dots li.slick-active button {
  background-color: #0a54ef;
}
.animate-show {
  opacity: 1 !important;
  transform: translateY(0) scale(1) !important;
}
.banner-list {
  height: 400px;
  img {
    width: 100%;
    height: 100%;
    cursor: pointer;
  }
}
.num-type {
  background-color: #eaeff6;
  height: 100px;
  ul {
    display: flex;
    height: 100%;
    li {
      display: flex;
      align-items: center;
      flex: 1;
      position: relative;
      padding-left: 35px;
      .con {
        margin-left: 19px;
        p:nth-child(1) {
          color: #171c46;
          font-size: 16px;
          font-weight: bold;
          margin-bottom: 10px;
          &:hover {
            color: #0a54ef;
          }
        }
        p:nth-child(2) {
          color: #5d6372;
          font-size: 14px;
        }
      }
      &:after {
        content: "";
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 1px;
        height: 50px;
        background: #d3d3d3;
      }
      &:last-child {
        &:after {
          content: none;
        }
      }
    }
  }
}
.rural {
  .commerce {
    height: 700px;
    background: #f8fbfe;
    overflow: hidden;
    .title {
      color: #171c46;
      font-size: 28px;
      font-weight: 600;
      text-align: center;
      margin: 90px 0 70px 0;
    }
    ul {
      display: flex;
      justify-content: space-between;
      li {
        height: 380px;
        width: 284px;
        background-color: #fff;
        border: 1px solid #e4e9ee;
        position: relative;
        transition: all 0.5s;
        .cover {
          height: 98px;
          display: flex;
          align-items: center;
          padding-left: 24px;
          color: #fff;
          font-size: 18px;
          font-weight: 600;
        }
        .content {
          color: #5d6372;
          line-height: 26px;
          padding: 20px 24px 0 24px;
        }
        .more {
          position: absolute;
          bottom: 20px;
          left: 50%;
          transform: translateX(-50%);
          width: 234px;
          height: 40px;
          line-height: 40px;
          text-align: center;
          border: 1px solid #0a54ef;
          cursor: pointer;
          color: #0a54ef;
        }
      }
    }
  }
  .agriculture {
    background-color: #fff;
    box-shadow: 0px 0px 40px 0px rgba(205, 213, 221, 0.35);
    #agriculture {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-top: 90px;
      opacity: 0;
      transform: translateY(50px) scale(0);
      transition: all 1.5s;
    }
    @keyframes agricultureAaim {
      0% {
        opacity: 0;
        transform: scale(0.5);
        top: 40px;
      }
      100% {
        opacity: 1;
        transform: scale(1);
        top: 0;
      }
    }
    .title {
      color: #171c46;
      font-size: 28px;
      font-weight: 600;
      margin-bottom: 35px;
    }
    p {
      color: #171c46;
    }
    ul {
      display: flex;
      margin-top: 40px;
      li {
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: 18px;
        color: #171c46;
        margin-right: 125px;
        font-weight: 600;
        cursor: pointer;
        &:last-child {
          margin: 0;
        }
        img {
          margin-bottom: 14px;
        }
        span {
          position: relative;
          height: 40px;
        }
        .agriculture-span-active:after {
          content: "";
          height: 2px;
          background: #0a54ef;
          width: 100%;
          position: absolute;
          bottom: 0;
          left: 50%;
          padding: 0 10px;
          box-sizing: content-box;
          transform: translateX(-50%);
        }
      }
    }
  }
  .agriculture_info {
    background: #f8fbfe;
    height: 500px;
    display: flex;
    align-items: center;
    .content {
      display: flex;
      opacity: 0;
      transform: translateY(100px) scale(0);
      transition: all 2s;
      .img {
        width: 460px;
        height: 350px;
        overflow: hidden;
        img {
          transition: all 0.3s;
          &:hover {
            transform: scale(1.2);
          }
        }
      }
      .info {
        margin-left: 76px;
        position: relative;
        .englishName {
          padding: 5px 17px;
          color: #0a54ef;
          border: 1px solid #0a54ef;
          display: inline-block;
          font-weight: 600;
          margin-bottom: 25px;
        }
        .name {
          font-size: 30px;
          font-weight: 600;
          color: #000;
          margin-bottom: 25px;
        }
        .introduction {
          font-size: 13px;
          color: #666;
          margin-bottom: 30px;
        }
        .more-info {
          font-size: 13px;
          div {
            color: #666;
            margin-bottom: 20px;
            span {
              color: #0a54ef;
            }
          }
        }
        .tryout {
          position: absolute;
          left: 0;
          bottom: 0;
        }
      }
    }
  }

  .travel {
    height: 800px;
    width: 100%;
    position: relative;
    .back {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
    .mask{
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 2;
      background-color: rgba(32,34,41,0.8);
    }
    .content {
      height: 100%;
      display: flex;
      position: absolute;
      width: 1200px;
      left: 50%;
      top: 0;
      transform: translateX(-50%);
      height: 100%;
      z-index: 3;
      .travel-type {
        width: 300px;
        height: 100%;
        position: relative;
        .travel-type-back {
          height: 100%;
          background: linear-gradient(-90deg, #37394a, #1d2028);
          opacity: 0.8;
        }
        ul {
          position: absolute;
          z-index: 2;
          left: 0;
          top: 0;
          width: 100%;
          margin-top: 240px;
          li {
            height: 120px;
            line-height: 120px;
            position: relative;
            color: #fff;
            font-size: 16px;
            padding-left: 87px;
            cursor: pointer;
            &:hover {
              background: linear-gradient(90deg, #37394a, #1d2028) !important;
            }
          }
          .travel-type-active:after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 3px;
            height: 100%;
            background: #0a54ef;
          }
        }
      }
      .travel-info {
        flex: 1;
        margin-left: 110px;
        color: #fff;
        .title {
          height: 240px;
          font-weight: 600;
          font-size: 28px;
          padding-left: 37px;
          padding-top: 96px;
        }
        .name {
          font-weight: 600;
          font-size: 24px;
          color: #fff;
          margin-bottom: 29px;
        }
        .introduction {
          opacity: 0.6;
          margin-bottom: 33px;
        }
        .more {
          margin-bottom: 28px;
          img {
            display: inline;
          }
        }
        .img {
          padding-top: 56px;
          border-top: 1px solid rgba(255, 255, 255, 0.2);
        }
      }
    }
  }
  .tool {
    background: #f8fbfe;
    overflow: hidden;
    #tool {
      opacity: 0;
      transform: translateY(50px) scale(0);
      transition: all 1.5s;
    }
    .title {
      color: #171c46;
      font-size: 28px;
      font-weight: 600;
      text-align: center;
      margin: 90px 0 60px 0;
    }
    .content {
      display: flex;
      align-items: center;
      .tool-info {
        margin-left: 30px;
        ul {
          li {
            display: flex;
            align-items: center;
            margin-bottom: 40px;
            .tool-name {
              margin-left: 20px;
              p:nth-child(1) {
                font-weight: 600;
                font-size: 16px;
                color: #171c46;
                margin-bottom: 10px;
                &:hover {
                  color: #0a54ef;
                }
              }
              p:nth-child(2) {
                color: #5d6372;
                line-height: 22px;
              }
            }
          }
        }
      }
    }
  }
  .win-win {
    height: 642px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .title {
      color: #171c46;
      font-size: 28px;
      font-weight: 600;
      text-align: center;
      margin-bottom: 60px;
    }
    ul {
      display: flex;
      justify-content: space-between;
      li {
        height: 360px;
        width: 288px;
        border: 1px solid #dddddd;
        background-color: #fff;
        position: relative;
        transition: all 0.5s;
        &:hover {
          box-shadow: 0px 0px 40px 0px rgba(205, 213, 221, 0.69);
        }
        .cover {
          height: 180px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #fff;
          font-size: 22px;
          font-weight: 600;
          position: relative;
          overflow: hidden;
          img {
            width: 100%;
            height: 100%;
            transition: all 0.4s;
            &:hover {
              transform: scale(1.2);
            }
          }
          span {
            width: 100%;
            text-align: center;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
        }
        p {
          padding: 0 30px;
          color: #171c46;
          font-size: 16px;
          line-height: 22px;
          margin-top: 22px;
          text-align: center;
        }
      }
    }
  }
}
.num-back {
  height: 300px;
  .num-data {
    height: 100%;
    display: flex;
    opacity: 0;
    transform: translateY(100px) scale(0);
    transition: all 1.5s;
    > div {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      color: #fff;
      align-items: center;
      P:nth-child(1) {
        font-weight: bold;
        font-size: 40px;
        margin-bottom: 30px;
        span {
          font-weight: 400;
          font-size: 16px;
          margin-left: 10px;
        }
      }
      p:nth-child(2) {
        font-size: 20px;
        opacity: 0.5;
      }
    }
  }
}
.news {
  padding: 90px 0 130px 0;
  opacity: 0;
  transform: translateY(150px) scale(0);
  transition: all 2s;
  .title {
    font-size: 28px;
    font-weight: 600;
    color: #171c46;
    text-align: center;
    margin-bottom: 62px;
  }
  .news-con {
    display: flex;
    .first-news {
      width: 450px;
      cursor: pointer;
      .cover {
        width: 100%;
        height: 230px;
      }
      .titles {
        color: #1a1824;
        font-size: 18px;
        font-weight: 600;
        padding: 33px 15px;
      }
      .date {
        display: flex;
        color: #737373;
        justify-content: space-between;
        margin-top: 10px;
      }
    }
    ul {
      flex: 1;
      margin-left: 70px;
      li {
        border-bottom: 1px solid #dadada;
        height: 74px;
        display: flex;
        align-items: center;
        font-size: 18px;
        cursor: pointer;
        &:hover {
          background: #f8fbfe;
        }
        &:first-child {
          border-top: 1px solid #dadada;
        }
        span {
          margin-left: 30px;
          color: #555;
          font-weight: bold;
        }
        div {
          margin-left: 83px;
          color: #666;
          &:hover {
            color: #0a54ef;
          }
        }
      }
    }
  }
}
.support {
  height: 250px;
  display: flex;
  align-items: center;
  ul {
    display: flex;
    opacity: 0;
    transform: translateY(100px) scale(0);
    transition: all 1.5s;
    li {
      flex: 1;
      display: flex;
      align-items: center;
      flex-direction: column;
      span {
        color: #fff;
        font-size: 18px;
        margin-top: 20px;
      }
    }
  }
}
.soft {
  height: 800px;
  display: flex;
  align-items: center;
  justify-content: center;
  #soft {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transform: translateY(100px) scale(0);
    transition: all 2s;
  }
  .title {
    margin-bottom: 35px;
    color: #1a1824;
    font-size: 28px;
    font-weight: 600;
  }
  > img {
    margin-bottom: 55px;
  }
  .introduction {
    color: #171c46;
    margin-bottom: 50px;
  }
}
