 input{
    border: none!important;
    outline: none!important;
}
textarea{
    border: none;
    outline: none;
}
.main{
    height: 840px;
    background: #FFFFFF;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    .text1{
        font-size: 28px;
        font-family: Source Han Sans CN;
        font-weight: 500;
        color: #171C46;
        margin-bottom: 35px;
    }
    .text2{
        font-size: 16px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #171C46;
        margin-bottom: 70px;
    }
    .form{
        width: 856px;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        .input_item{
            width: 400px;
            height: 64px;
            background: #FFFFFF;
            box-shadow: 0px 3px 3px 0px rgba(3, 22, 76, 0.17);
            display: flex;
            align-items: center;
            margin-bottom: 26px;
            padding: 0 24px;
            box-sizing: border-box;
            .star{
                font-size: 16px;
                font-family: Source Han Sans CN;
                font-weight: 500;
                color: #E52020;
                margin-right: 2px;
            }
            input{
                flex: 1;
                font-size: 16px;
                &::-webkit-input-placeholder{
                    color: #999999;
                }
            }
            .right{
                flex: 1;
                font-size: 16px;
                input{
                    padding: 1px 0!important;
                }
            }
        }
        .input_item2{
            width: 856px;
            height: 130px;
            background: #FFFFFF;
            align-items: flex-start;
            padding:24px;
            textarea{
                flex: 1;
                font-size: 16px;
                height: 100%;
            }
        }
    }
    .submit{
        width: 200px;
        height: 50px;
        background: #0A54EF;
        border: 1px solid #1F78F2;
        box-shadow: 0px 3px 3px 0px rgba(3, 22, 76, 0.17);
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 16px;
        font-family: Source Han Sans CN;
        font-weight: 500;
        color: #FFFEFE;
        margin: 14px auto 0;
        cursor: pointer;
        img{
            width: 16px;
            height: 16px;
            margin-left: 9px;
        }
    }
    .message{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        width: 348px;
        height: 197px;
        background: rgba(0,0,0,0.7);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .message_text1{
            font-size: 16px;
            font-family: Source Han Sans CN;
            font-weight: 500;
            color: #FFFFFF;
        }
        .iknow{
            width: 156px;
            height: 44px;
            border: 1px solid #FFFFFF;
            margin-top: 45px;
            line-height: 44px;
            text-align: center;
            font-size: 16px;
            font-family: Source Han Sans CN;
            font-weight: 500;
            color: #FFFFFF;
            cursor: pointer;
        }
    }
}
