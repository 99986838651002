.banner {
  height: 400px;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .banner_image {
    position: absolute;
    top: 0;
    left: 50%;
    height: 400px;
    width: 1920px;
    transform: translateX(-50%);
    z-index: -1;
  }
  .banner_text1 {
    font-size: 44px;
    font-family: Source Han Sans CN;
    font-weight: bold;
    color: #ffffff;
    margin-bottom: 24px;
  }
  .banner_text2 {
    font-size: 14px;
    font-family: Source Han Sans CN;
    font-weight: 500;
    color: #ffffff;
    margin-bottom: 32px;
  }
  .input {
    display: flex;
    background: #ffffff;
    border: 1px solid #ffffff;
    border-radius: 20px;
    input {
      padding: 0 17px;
      font-size: 14px;
      border-radius: 20px;
      flex: 1;
    }
    div {
      width: 112px;
      height: 40px;
      background: #0a54ef;
      border-radius: 20px;
      line-height: 40px;
      text-align: center;
      font-size: 14px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #ffffff;
      cursor: pointer;
    }
  }
}
