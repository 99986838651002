.industry {
  padding: 85px 0 110px 0;
  .title {
    font-size: 28px;
    color: #171c46;
    font-weight: 600;
    text-align: center;
  }
  ul {
    display: flex;
    margin-top: 75px;
    justify-content: space-between;
    li {
      height: 318px;
      width: 216px;
      position: relative;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
        transition: all 0.3s;
        &:hover {
          transform: scale(1.2);
        }
      }
      div {
        font-weight: 600;
        font-size: 20px;
        color: #fff;
        position: absolute;
        z-index: 2;
        height: 50px;
        bottom: 10px;
        left: 0;
        width: 100%;
        line-height: 50px;
        text-align: center;
        background-color: rgba(0, 0, 0, 0.6);
      }
    }
  }
}
.scenes {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 85px 0 110px 0;
  .title {
    font-size: 28px;
    color: #171c46;
    font-weight: 600;
    margin-bottom: 60px;
  }
  ul {
    display: flex;
    li {
      width: 400px;
      background-color: #fff;
      .img {
        width: 400px;
        height: 291px;
        overflow: hidden;
        img {
          width: 100%;
          height: 100%;
          transition: all 0.3s;
          &:hover{
            transform: scale(1.2);
          }
        }
      }
      .content {
        padding: 35px 67px 0 67px;
        height: 276px;

        .name {
          font-weight: 600;
          font-size: 18px;
          color: #171c46;
          margin-bottom: 15px;
          text-align: center;
        }
        p {
          color: #5d6372;
          line-height: 30px;
        }
      }
    }
  }
}
.equipment {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 85px 0 75px 0;
  .title {
    font-size: 28px;
    color: #171c46;
    font-weight: 600;
    margin-bottom: 85px;
  }
  ul {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 1200px;
    li {
      width: 574px;
      height: 184px;
      background: #fff;
      box-shadow: 0px 0px 36px 2px rgba(179, 179, 179, 0.1);
      border-radius: 10px;
      padding: 23px 0 23px 23px;
      display: flex;
      align-items: center;
      margin-bottom: 45px;
      &:hover {
        .content div {
          color: #0a54ef;
        }
      }
      .content {
        margin-left: 20px;
        div {
          font-weight: 600;
          font-size: 18px;
          color: #171c46;
          margin-bottom: 20px;
        }
        p {
          color: #5d6372;
          line-height: 24px;
        }
      }
    }
  }
}
.process {
  height: 600px;
  display: flex;
  flex-direction: column;
  align-items: center;
  .title {
    font-size: 28px;
    color: #fff;
    font-weight: 600;
    margin-top: 109px;
    margin-bottom: 80px;
  }
  .stage {
    width: 1200px;
    height: 60px;
    background: linear-gradient(-90deg, #3f78ee 0%, #175ef2 100%);
    border-radius: 10px;
    font-size: 18px;
    color: #fff;
    font-weight: 600;
    display: flex;
    align-items: center;
    margin-bottom: 32px;
    div:nth-child(1) {
      width: 254px;
      padding-left: 74px;
    }
    div:nth-child(3) {
      width: 286px;
      padding-left: 99px;
    }
    div:nth-child(5) {
      width: 298px;
      padding-left: 98px;
    }
    div:nth-child(7) {
      width: 237px;
      padding-left: 89px;
    }
  }
  .stage-name {
    width: 1200px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    div {
      width: 221px;
      display: flex;
      flex-direction: column;
      align-items: center;
      span {
        font-size: 18px;
        color: #fff;
        font-weight: 600;
        margin-top: 16px;
      }
    }
  }
  .stage-info {
    width: 1200px;
    display: flex;
    justify-content: space-between;
    div {
      border: 2px dashed #3c4055;
      width: 221px;
      height: 72px;
      padding: 0 26px;
      color: #fff;
      display: flex;
      align-items: center;
      border-radius: 10px;
      text-align: center;
      p {
        line-height: 24px;
        opacity: 0.8;
        font-weight: 600;
      }
    }
  }
}
