.logo{
    height: 700px;
    position: relative;
     display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .bj{
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        height: 700px;
        width: 100%;
        z-index: -1;
    }
    .logo_title{
        font-size: 28px;
        font-family: Source Han Sans CN;
        font-weight: 500;
        color: #171C46;
        margin-bottom: 38px;
    }
    .logo_text{
        font-size: 14px;
        font-family: Source Han Sans CN;
        font-weight: 500;
        color: #171C46;
        margin-bottom: 90px;
    }
    .logo_box{
        display: grid;
        grid-template-columns: repeat(6,182px);
        grid-template-rows: repeat(3,78px);
        gap: 22px 20px;
        .logo_item{
            width: 183px;
            height: 78px;
            background: #FFFFFF;
            display: flex;
            align-items: center;
            justify-content: center;
            box-shadow: 0px 3px 23px 1px rgba(151, 173, 196, 0.14);
            transition: 0.4s;
            img{
                max-width: 183px;
                max-height: 78px;
            }
            &:hover{
                transform: scale(1.1);
            }
        }
    }
}
