footer {
  height: 420px;
  background: #0b1d2f;
  .footer-con {
    .top {
      height: 318px;
      display: flex;
      padding: 69px 0 59px 0;
      .left {
        width: 296px;
        border-right: 1px solid #17293b;
        .f_logo {
          width: 131px;
          height: 26px;
        }
        .phone {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 150px;
          height: 36px;
          background: #0a54ef;
          color: #fff;
          font-size: 16px;
          margin: 32px 0 40px 0;
          cursor: pointer;
          img {
            width: 14px;
            height: 17px;
            margin-right: 9px;
          }
        }
        .email,
        .address {
          display: flex;
          align-items: center;
          span {
            color: #dddff0;
            opacity: 0.6;
          }
        }
        .email {
          margin-bottom: 20px;
          img {
            width: 15px;
            height: 12px;
            margin-right: 11px;
          }
        }
        .address {
          img {
            width: 12px;
            margin-right: 13px;
            height: 15px;
          }
        }
      }
      .right {
        flex: 1;
        display: flex;
        justify-content: space-between;
        padding-left: 73px;
        & > div {
          .title {
            color: #fff;
            font-size: 16px;
            opacity: 0.8;
            margin-bottom: 30px;
          }
          .item {
            opacity: 0.6;
            margin-bottom: 24px;
            a {
              color: #dddff0;
              &:hover {
                color: #0a54ef;
                cursor: pointer;
              }
            }
          }
          &:last-child .item {
            opacity: 1;
          }
        }
      }
    }
    .bot {
      border-top: 1px solid #17293b;
      display: flex;
      justify-content: space-between;
      padding-top: 24px;
      p {
        color: #dddff0;
        opacity: 0.4;
        span{
          cursor: pointer;
          &:hover{
            text-decoration: underline;
          }
        }
      }
      div {
        display: flex;
        align-items: center;
        span {
          margin-left: 10px;
          color: #dddff0;
          opacity: 0.4;
        }
      }
    }
  }
}
