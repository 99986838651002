.banner2 {
  height: 500px;
  position: relative;
  overflow: hidden;

  .banner_image {
    position: absolute;
    top: 0;
    left: 50%;
    height: 500px;
    width: 1920px;
    transform: translateX(-50%);
    z-index: -1;
  }
  .banner_top{
      display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 400px;
        .banner_text1 {
        font-size: 44px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        color: #ffffff;
        margin-bottom: 24px;
    }
    .banner_text2 {
        font-size: 14px;
        font-family: Source Han Sans CN;
        font-weight: 500;
        color: #ffffff;
        margin-bottom: 32px;
    }
    .input {
        display: flex;
        background: #ffffff;
        border: 1px solid #ffffff;
        border-radius: 20px;
        input {
        padding: 0 17px;
        font-size: 14px;
        border-radius: 20px;
        flex: 1;
        }
        div {
        width: 112px;
        height: 40px;
        background: #0a54ef;
        border-radius: 20px;
        line-height: 40px;
        text-align: center;
        font-size: 14px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #ffffff;
        cursor: pointer;
        }
    }

  }
  .banner_bottom{
        height: 100px;
        background: rgba(247,248,250,0.18);
        display: flex;
        justify-content: center;
        align-items: center;
        .part1{
            position: relative;
            padding-right: 118px;
            &::after{
                content: '';
                position: absolute;
                right: 0;
                top: 8px;
                width: 1px;
                height: 40px;
                background: #FFFFFF;
                opacity: 0.2;
            }
        }
        .part2{
            position: relative;
            padding: 0 89px;
            &::after{
                content: '';
                position: absolute;
                right: 0;
                top: 8px;
                width: 1px;
                height: 40px;
                background: #FFFFFF;
                opacity: 0.2;
            }
        }
        .part3{
            position: relative;
            padding: 0 100px;
            &::after{
                content: '';
                position: absolute;
                right: 0;
                top: 8px;
                width: 1px;
                height: 40px;
                background: #FFFFFF;
                opacity: 0.2;
            }
        }
        .part4{
            padding-left: 89px;
        }
        .text1{
            font-size: 14px;
            font-family: Source Han Sans CN;
            font-weight: 500;
            color: #FFFFFF;
            span{
                font-size: 30px;
                font-weight: bold;
                margin-right: 7px;
            }
        }
        .text2{
            font-size: 16px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #FFFFFF;
            opacity: 0.5;
            margin-top: 14px;
        }
    }

}
