.news_main{
    padding: 94px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    .text1{
        font-size: 28px;
        font-family: Source Han Sans CN;
        font-weight: 500;
        color: #171C46;
        margin-bottom: 33px;
    }
}
.swiper{
    display: flex;
    justify-content: center;
    align-items: center;
    .btn{
        width: 60px;
        height: 60px;
        box-shadow: 0px 3px 20px 0px rgba(5, 29, 69, 0.1);
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        .previmage{
            width: 24px;
            height: 24px;
            background-image: url('https://boweisou.oss-cn-shenzhen.aliyuncs.com/website/news/i_arrow_4.png');
        }
        .nextimage{
            width: 24px;
            height: 24px;
            background-image: url('https://boweisou.oss-cn-shenzhen.aliyuncs.com/website/news/i_arrow_6.png');
        }
        &:hover{
            background: #0A54EF;
            .previmage{
                background-image: url('https://boweisou.oss-cn-shenzhen.aliyuncs.com/website/news/i_arrow_3.png');
            }
            .nextimage{
                 background-image: url('https://boweisou.oss-cn-shenzhen.aliyuncs.com/website/news/i_arrow_5.png');
            }
        }
    }
    .box{
        width: 1270px;
        padding: 32px 36px 40px;
        overflow: hidden;
    }
    .swiperlist{
        display: flex;
        position: relative;
    }
    .move{
        transition: all 0.3s;
    }
    .swiper_item{
        width: 374px;
        height: 338px;
        background: #FFFFFF;
        box-shadow: 0px 4px 35px 0px rgba(191, 191, 191, 0.14);
        flex-shrink: 0;
        cursor: pointer;
        &:not(:last-child){
            margin-right: 38px;
        }
        &:hover{
            box-shadow: 0px 4px 35px 0px rgba(191, 191, 191, 0.54);
        }
        img{
            width: 374px;
            height: 210px;
            object-fit: cover;
        }
        .swiper_text{
            padding: 20px;
            .swiper_title{
                font-size: 18px;
                font-family: Source Han Sans CN;
                font-weight: 500;
                color: #1A1824;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
            .swiper_text2{
                font-size: 14px;
                font-family: Source Han Sans CN;
                font-weight: 400;
                color: #737373;
                 white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                margin-top: 14px;
            }
            .swiper_bottom{
                display: flex;
                align-items: center;
                margin-top: 18px;
                img{
                    width: 20px;
                    height: 20px;
                }
                div{
                    flex: 1;
                    padding: 0 2px;
                    font-size: 14px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #AAAAAA;
                }
            }
        }
    }
}
.list{
    margin-top: 40px;
    .list_item{
        padding: 40px 0;
        width: 1200px;
        border-bottom: 1px solid #F0F0F0;
        background-color: #fff;

        display: flex;

        img{
            width: 320px;
            height: 180px;
            margin-right: 40px;
        }
        .list_center{
            flex:1;
            width: 0;
            .list_title{
                font-size: 18px;
                font-family: Source Han Sans CN;
                font-weight: 500;
                color: #1A1824;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
            .list_text{
                font-size: 14px;
                font-family: Source Han Sans CN;
                font-weight: 400;
                color: #737373;
                line-height: 24px;
                margin-top: 28px;
            }
            .list_bottom{
                display: flex;
                align-items: center;
                margin-top: 28px;
                img{
                    width: 20px;
                    height: 20px;
                    margin-right: 2px;
                }
                div{
                    font-size: 14px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #AAAAAA;
                }
            }
        }
        .list_look{
            width: 120px;
            height: 32px;
            border: 1px solid #777777;
            margin: 110px 14px 0 88px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 14px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #777777;
            cursor: pointer;
            .list_icon{
                width: 20px;
                height: 20px;
                 background-image: url('https://boweisou.oss-cn-shenzhen.aliyuncs.com/website/news/i_arrow_1.png');
            }
        }
        &:hover{
            background-color: #FAFBFC;
            .list_title{
                color: #0A54EF;
            }
            .list_icon{
                 background-image: url('https://boweisou.oss-cn-shenzhen.aliyuncs.com/website/news/i_arrow_2.png');
            }
            .list_look{
                color: #0A54EF;
                border-color: #0A54EF;
            }
        }
    }
}
.page{
    margin-top: 48px;
    display: flex;
    justify-content: center;

    .ant-pagination-item{
        width: 32px;
        height: 32px;
        background: #FFFFFF;
        box-shadow: 1px 1px 4px 0px rgba(0, 0, 0, 0.15);
        border: none;
        border-radius: 2px;
        cursor: pointer;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #2D2D2D;
        text-align: center;
        line-height: 32px;
        &:hover{
            background-color: #0A54EF;
            a{
                color: #ffffff;
            }
        }
        a{
            width: 100%;
            height: 100%;
            text-align: center;
            padding: 0;
        }
    }
    .ant-pagination-item-active{
        background-color: #0A54EF;
        a{
            color: #ffffff;
        }
    }
    .ant-pagination-prev ,.ant-pagination-next {
        width: 66px;
        height: 32px;
        background: #FFFFFF;
        box-shadow: 1px 1px 4px 0px rgba(0, 0, 0, 0.15);
        border-radius: 2px;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #2D2D2D;
        text-align: center;
        line-height: 32px;
        a{
            color: #2D2D2D;
        }
    }
    li:not(:last-child){
        margin-right: 9px;
    }
    .ant-pagination-disabled{
        a{
            color: #999999;
        }
    }
}
