.problem{
    margin-top:20px;
   header{
       h2{
           font-size:28px;
           font-weight: bold;
           padding-bottom: 20px;
           border-bottom: 1px solid #ebebf2;
       }
   }
   .problem-list{
       
   }
}