.jump-btn{
    height: 40px;
    padding:0 20px;
    background: #0A54EF;
    align-items: center;
    font-size: 14px;
    color: #fff;
    line-height: 40px;
    display: inline-block;
    cursor: pointer;
    &:hover{
        opacity: 0.8;
    }
    img{
        margin-left:7px;
    }
}