.tools {
  height: 590px;
  background: #f8fbfe;
  .content {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .title {
      font-size: 28px;
      font-weight: 600;
      color: #171c46;
      margin-bottom: 40px;
      text-align: center;
    }
    ul {
      display: flex;
      justify-content: space-between;
      li {
        width: 384px;
        height: 320px;
        background-color: #fff;
        border: 1px solid #eaeaea;
        padding: 0 20px;
        color: #333;
        .top {
          height: 150px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          border-bottom: 1px solid #eaeaea;
          font-weight: 600;
          img {
            margin-bottom: 14px;
            transition: all 0.3s;
            &:hover{
              transform: scale(1.2);
            }
          }
        }
        .bot {
          padding-top: 30px;
          line-height: 30px;
          text-align: center;
        }
      }
    }
  }
}
.process {
  height: 490px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  .title {
    font-size: 28px;
    font-weight: 600;
    color: #171c46;
    margin-bottom: 40px;
    text-align: center;
    margin-bottom: 50px;
  }
  .content {
    height: 200px;
    width: 100%;
    background: #fff;
    border: 1px solid #eaeaea;
    box-shadow: 0px 8px 40px 0px rgba(205, 213, 221, 0.35);
    display: flex;
    justify-content: center;
    .item {
      display: flex;
      align-items: center;
      margin-right: 55px;
      &:last-child {
        margin: 0;
      }
      .name {
        display: flex;
        align-items: center;
        color: #333;
        font-weight: 600;
        .icon {
          margin-right: 17px;
        }
        div p:nth-child(1) {
          margin-bottom: 5px;
        }
      }
      .jiantou {
        margin-left: 59px;
      }
    }
  }
}
.func {
  height: 730px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #fff;
  .name {
    font-size: 28px;
    font-weight: 600;
    margin-bottom: 30px;
  }
  .introduction {
    opacity: 0.7;
    margin-bottom: 80px;
  }
}
.system-admin {
  height: 783px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  .title {
    font-size: 28px;
    font-weight: 600;
    color: #171c46;
    text-align: center;
    margin-bottom: 30px;
  }
  .introduction {
    color: #171c46;
    text-align: center;
    margin-bottom: 50px;
  }
  ul {
    display: flex;
    justify-content: space-between;
    li {
      width: 384px;
      height: 450px;
      background: #ffffff;
      border: 1px solid #ececec;
      .img {
        width: 100%;
        height: 220px;
        overflow: hidden;
        img {
          width: 100%;
          height: 100%;
          transition: all 0.3s;
          &:hover {
            transform: scale(1.2);
          }
        }
      }
      .text {
        padding: 0 29px;
        .name {
          display: flex;
          align-items: center;
          font-size: 22px;
          color: #171c46;
          font-weight: 600;
          margin: 30px 0;
          span {
            width: 4px;
            height: 20px;
            background: #0a54ef;
            margin-right: 10px;
          }
        }
        p {
          color: #666;
          line-height: 24px;
        }
      }
    }
  }
}
